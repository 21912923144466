<template>
    <div class="px-4">
        <div class="grid align-items-center mb-5">
            <div class="col-12 sm:col-6 lg:mb-0 pb-0">
                <form class="grid formgrid" @submit.prevent="doSearch()">
                    <div class="col-9 mb-3 lg:mb-0">
                        <span class="p-input-icon-right w-full">
                            <InputText type="text" placeholder="Cari disini" class="w-full" v-model="keyword" />
                            <i class="pi pi-search" />
                        </span>
                    </div>
                    <div class="col-2 mb-3 lg:mb-0">
                        <Button label="" type="submit" icon="pi pi-filter-fill" class="btn-white px-2">
                            <icon-enter></icon-enter>
                        </Button>
                    </div>
                </form>
            </div>
            <div class="col-12 mb-2 sm:col-6 text-right lg:mb-0 py-0">
                <Button label="Tambah Servis" type="submit" icon="pi pi-plus" class="btn-primary" @click="showForm()" />
                <Dialog v-model:visible="show_form"
                    :showHeader="true"
                    :draggable="false"
                    :closeOnEscape="true" :style="{width: '500px'}"
                    :modal="true"
                    class="p-0"
                    contentClass="p-0"
                    :breakpoints="{'575px': '95vw'}">
                    <template #header>
                        <h5 class="text-black roboto-medium mb-0">
                            {{ form.id ? 'Edit' : 'Tambah' }} Servis
                        </h5>
                    </template>
                    <hr class="mt-0">
                    <service-form :data="form"
                        @cancel="closeForm"
                        @updated="updateForm"
                        @submit="doSubmit">
                    </service-form>
                    <template #footer>
                        <Button label="Batal" @click="show_form = false" class="btn-outline-primary mr-3 w-auto" />
                        <Button type="submit"
                            class="btn-primary text-white roboto-semibold font-bold justify-content-center w-auto px-3"
                            :disabled="isDisabled || form.is_submit"
                            @click="doSubmit()">
                            <span v-if="!form.is_submit">Simpan</span>
                            <i class="pi pi-spin pi-spinner" v-else></i>
                        </Button>
                    </template>
                </Dialog>
            </div>
        </div>

        <table-list :data="data" :columns="columns"
            :loading="loading"
            :offset="offset"
            :limit="limit"
            :actions="['edit', 'delete']"
            @edit="showForm"
            @delete="deleteConfirmation"
            @callbackPaginate="currentPage">
        </table-list>
    </div>

    <Dialog header="Confirmation" v-model:visible="delete_confirmation" :style="{width: '350px'}" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>Are you sure you want to proceed?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" @click="delete_confirmation = false" class="p-button-text" :disabled="form.is_submit" />
            <Button class="p-button-text p-button-danger"
                @click="deleteItem(storage)"
                :disabled="form.is_submit">
                <span v-if="!form.is_submit">Yes</span>
                <i class="pi pi-spin pi-spinner" v-else></i>
            </Button>
        </template>
    </Dialog>
</template>

<script>
import TableList from '@/components/Table'
import ServiceForm from './Form.vue'
import IconEnter from '@/components/IconEnter'

export default {
    components: {
        TableList, ServiceForm, IconEnter,
    },
    data() {
        return {
            moment: require('moment-timezone'),
            loading: false,
            limit: 10,
            offset: 0,
            keyword: '',
            show_form: false,
            form: {},
            isDisabled: true,
            delete_confirmation: false,
            data: [],
            all_data: [],
            storage: null,
            columns: [
                { field: 'id', header: 'ID', sortable: true },
                { field: 'name', header: 'Nama Produk', sortable: true, position: 'left' },
                { field: 'grpc_address', header: 'GRPC Address', sortable: true },
                { 
                    field: 'status',
                    header: 'Status',
                    sortable: true,
                    process: (data) => this.status[data],
                },
            ],
            status: {
                0: { label: 'Inactive', type: 'tag', class: 'mr-2 py-1 text-10 border-round-lg bg-linear-red', icon: 'pi pi-times' },
                1: { label: 'Active', type: 'tag', class: 'mr-2 py-1 text-10 border-round-lg bg-linear-green', icon: 'pi pi-check' },
            },
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            this.$http
                .get(`${process.env.VUE_APP_API_URL}/service?name=${this.keyword}`)
                .then(response => {
                    this.loading = false
                    this.data = response.data.data.services
                    this.all_data = this.data
                })
        },
        doSearch() {
            let keyword = this.keyword.toLowerCase()

            if (keyword === 'active') {
                this.data = this.all_data.filter(item => item.status === 1)
                return
            } else if (keyword === 'inactive') {
                this.data = this.all_data.filter(item => item.status === 0)
                return
            }

            this.data = this.all_data.filter(
                (item) =>
                    item.name.toLowerCase().includes(keyword) ||
                    item.grpc_address.toLowerCase().includes(keyword)
            );
        },
        currentPage(event) {
            this.limit = event.rows
            this.offset = event.page * this.limit
        },
        showForm(data = null) {
            this.show_form = true
            this.form = {}

            if (data) {
                this.form = {...this.form, ...data}
            }
        },
        closeForm() {
            this.show_form = false
        },
        updateForm(data) {
            this.form = data
            this.isDisabled = !this.form['name'] || !this.form['grpc_address']
        },
        doSubmit() {
            let method = this.form['id'] ? 'put' : 'post'
            let URI = this.form['id'] ? `service/${this.form['id']}` : `service`
            
            this.form['is_submit'] = true
            this.form['status'] = this.form['status_toggle'] ? 1 : 0
            this.$http[method](`${process.env.VUE_APP_API_URL}/${URI}`, this.form)
                .then(response => {
                    this.form['is_submit'] = false
                    if (response.data.code !== 200) {
                        this.$toast.add({
                            severity:'error', 
                            summary: 'Error!', 
                            detail: response.data.message, 
                            life: 5000,
                        })
                        return
                    }

                    this.show_form = false

                    if (!response.data.data.id) {
                        let index = this.data.findIndex(obj => obj.id === this.form['id'])
                        this.data[index] = {...this.data[index], ...this.form}
                    } else {
                        this.form['id'] = response.data.data.id
                        this.data.unshift(this.form)
                    }

                    this.$toast.add({
                        severity:'success', 
                        summary: 'Success!', 
                        detail: response.data.message, 
                        life: 5000,
                    })
                })
        },
        deleteConfirmation(data) {
            this.delete_confirmation = true
            this.storage = data
        },
        deleteItem(data) {
            this.form['is_submit'] = true

            this.$http.delete(`${process.env.VUE_APP_API_URL}/service/${data.id}`)
                .then(response => {
                    this.form['is_submit'] = false

                    if (response.data.code !== 200) {
                        this.$toast.add({
                            severity:'error', 
                            summary: 'Error!', 
                            detail: response.data.message, 
                            life: 5000,
                        })
                        return
                    }

                    let index = this.data.findIndex(obj => obj.id === data.id)
                    this.data.splice(index, 1)
                    this.delete_confirmation = false

                    this.$toast.add({
                        severity:'success', 
                        summary: 'Success!', 
                        detail: response.data.message, 
                        life: 5000,
                    })
                })
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
