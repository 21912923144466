<template>
    <div class="px-4 text-14 pb-5">
        <form class="p-fluid pt-2" @submit.prevent="">
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    Nama Servis *
                </label>
                <InputText type="text" placeholder="Nama Servis" v-model="form.name" :disabled="form.is_submit" />
            </div>
            <div class="field mb-4">
                <label class="text-black text-14 roboto-medium">
                    GRPC Address *
                </label>
                <InputText type="text" placeholder="127.0.0.1:9000" v-model="form.grpc_address" :disabled="form.is_submit" />
            </div>
            <div class="field">
                <label class="text-black text-14 roboto-medium mb-3">
                    Status
                </label>
                <div class="flex align-items-center">
                    <InputSwitch v-model="form.status_toggle" :disabled="form.is_submit" />
                    <label class="text-black opacity-60 ml-2">
                        Aktif
                    </label>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: ['data',],
    data() {
        return {
            form: {
                id: null,
                name: '',
                grpc_address: '',
                status_toggle: false,
                status: 0,
                is_submit: false,
            },
        }
    },
    watch: {
        'form': {
            handler: function() {
                this.$emit('updated', this.form)
            },
            deep: true,
        },
    },
    mounted() {
        if (this.data) {
            this.form = {...this.form, ...this.data}
            this.form.status_toggle = this.form.status ? true : false
        }
    },
    methods: {
        
    },
}
</script>

<style lang="scss" scoped></style>
